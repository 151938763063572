<template>
  <div>
    <flixDashboard>
      <template v-slot:content>
        <div class="flix-container-fluid">
          <flixCheckPermissions role="calendar" permission="2" error>
              <div class="flix-row">
                <div class="flix-col-md-12 flix-text-center" v-if="$route.params.id !== 'new'">
                  <h2 class="flix-html-h2">Online-Termin-Kalender #{{ $route.params.id }}</h2>
                </div>
                <div class="flix-col-md-2 leftMenu flix-form-group" v-if="$route.params.id !== 'new'">
                  <calendarEmbed :id="$route.params.id" />
                </div>
                <div class="flix-form-group" :class="{'flix-col-md-8': $route.params.id !== 'new', 'flix-col-md-8 flix-col-md-flix-offset-2': $route.params.id === 'new'}">
                  <calendarAssistent />
                </div>
              </div>
          </flixCheckPermissions>
        </div>
      </template>
    </flixDashboard>
  </div>
</template>

<script>
export default {
  components: {
    calendarAssistent () { return import('@/components/assistent/edit.vue') },
    calendarEmbed () { return import('@/components/embed/index.vue') }
  }
}
</script>
<style scoped lang="sass">
  .leftMenu
    background-color: lightgrey
    padding: 10px
    box-sizing: border-box
</style>
